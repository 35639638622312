import { Image } from '@graphcommerce/image'
import { Accordion, AccordionDetails, AccordionSummary, Paper } from '@mui/material'
import { useState } from 'react'
import expandIcon from '../../assets/images/arrow-down.svg'
import step1 from '../../assets/images/order-process-step1.svg'
import step2 from '../../assets/images/order-process-step2.svg'
import step3 from '../../assets/images/order-process-step3.svg'
import step4 from '../../assets/images/order-process-step4.svg'
import { MenuAccordion } from '../Header/SubHeaderTabsMobile'
import { IconDescriptionGrid } from '../Home/WhyUseTempli'
import { qaData } from '../Reviews/data'

interface OrderProcessGrid {
  step: number
  title: string
  description: string
}

interface OrderProcessQuestionsGrid {
  question: string
  answer: string
}

export function OrderProcess() {
  const [openedIndex, setOpenedIndex] = useState<number>(-1)

  const data: OrderProcessGrid[] = [
    {
      step: 1,
      title: 'Choose your item and upload your art.',
      description:
        'High quality JPG, PDF, or Vector format (svg, eps) or an AI (Adobe Illustrator) file.',
    },
    {
      step: 2,
      title: 'Add item to cart and complete checkout.',
      description:
        'We accept several payment methods, as well as the option to PayLater with Paypal.',
    },
    {
      step: 3,
      title: 'Receive unlimited proofs.',
      description:
        'If you opted for proofing before printing, you must approve your proof in “Your Orders” tab before it goes to production.',
    },
    {
      step: 4,
      title: 'Order ships!',
      description: 'You’ll automatically receive an email with your tracking information. ',
    },
  ]

  const qAData: OrderProcessQuestionsGrid[] = [
    {
      question: 'Will I receive a proof before my order prints?',
      answer:
        'When uploading your artwork, you can choose if you want to receive a proof. If choosing yes, we’ll email you a proof usually within one business day. You have unlimited edits until you’re 100% happy with your layout. Only once approved, will your order move into production.',
    },
    {
      question: 'Can I receive a sample of the item before ordering?',
      answer: `Yes, you can request product samples by emailing your sample contact@templi.com - The samples sent will have previously printed designs we’ve produced, not your own custom design. For a few specific items, we offer custom pre-production samples, but only with a submitted order. We do not offer custom samples for potential orders.`,
    },
    {
      question: 'How long will it take to receive my order?',
      answer:
        'Every product has a unique lead time. You can view the lead time on each product page, just below the product title. But generally speaking, print items are 1 week, merch is 2 weeks and packaging can be 4-9 weeks.',
    },
    {
      question: 'Can Templi help me setup my design?',
      answer:
        'While Templi does not offer ‘logo creation’ services, we can absolutely help you layout your existing artwork onto our templates and ensure your design looks perfect. You can even schedule a video chat or phone call with a Templi designer. Just reach out to us at contact@templi.com',
    },
    {
      question: 'Why is there a setup cost and why is it so high?',
      answer:
        'Printing is often carried out by large expensive machines which require time, sometimes an hour, to create the metal printing plates, which can only be used for your design. The packaging industry typically charges for the creation and storage of these printing plates for up to 2 years. In most cases, these are one time setup costs. For a few items, you will have a reduced ‘repeat setup’ cost.',
    },
  ]

  return (
    <div className='flex w-full items-center justify-center'>
      <div className='mx-[10px] mb-4 flex w-full max-w-maxContentWidth flex-col items-center justify-center bg-[#FFFFFF] px-5 md:mx-[20px] md:px-[60px]'>
        <span className='Type-XXL-Medium md:Type-XXXL-Medium mb-9 mt-12 flex w-full items-center justify-start rounded-[10px] bg-none text-center text-black md:items-start'>
          How our ordering process works
        </span>
        <div className='flex w-full grid-cols-2 flex-col justify-center justify-items-center gap-x-[72px] gap-y-7 pb-12 md:grid'>
          <div className='flex flex-col gap-y-6'>
            {data?.map((item) => (
              <div className='grid w-full grid-cols-[5fr_95fr] items-center justify-items-center gap-x-4'>
                <div className='flex h-[32px] w-[32px] items-center justify-center rounded-[50%] bg-[#F4E9FF]'>
                  <span className='Type-XXXL-Bold text-bright-templi-purple'>{item.step}</span>
                </div>
                <div className='flex w-full flex-col items-start justify-center'>
                  <span className='Type-XL-Medium'>{item.title}</span>
                  <span className='Type-Large-Regular text-70-grey'>{item.description}</span>
                </div>
              </div>
            ))}
          </div>
          <div className='mt-5 flex h-fit w-full flex-col border-t-[1px] border-[#F5F5F5] md:mt-0'>
            {qAData.map((qaItem, index) => (
              <MenuAccordion
                sx={{
                  boxShadow: 'none',
                  borderRadius: 0,
                  '&:before': { height: '0px' },
                  borderBottom: '1px solid #F5F5F5',
                }}
                expanded={index === openedIndex}
                onClick={() => {
                  if (index === openedIndex) {
                    setOpenedIndex(-1)
                  } else {
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                    setOpenedIndex(index)
                  }
                }}
              >
                <AccordionSummary
                  expandIcon={
                    <Image
                      src={expandIcon}
                      alt='expand or close'
                      layout='fixed'
                      unoptimized
                      height={40}
                      width={40}
                    />
                  }
                  className='Type-XXL-Regular'
                >
                  <h3 className='Type-XL-Medium'>{qaItem.question}</h3>
                </AccordionSummary>
                <AccordionDetails
                  className='my-[10px] md:my-[20px] md:mt-0'
                  sx={{
                    padding: '0',
                  }}
                >
                  <h3 className='Type-Large-Regular text=[##757575] leading-[20px]'>
                    {qaItem.answer}
                  </h3>
                </AccordionDetails>
              </MenuAccordion>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
