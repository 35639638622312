import { Image } from '@graphcommerce/image'
import { i18n } from '@lingui/core'
import { Paper } from '@mui/material'
import * as React from 'react'
import bgImage from '../../assets/images/home-page-newsletter-bg.png'
import HomePageNewsletterForm from './HomePageNewsletterForm.'

type NewsLetterSectionProps = {
  isFromBlog?: boolean
}
const HomePageNewsletterSection: React.FC<NewsLetterSectionProps> = (
  props: NewsLetterSectionProps,
) => {
  const { isFromBlog = true } = props
  const [isSubscriptionSuccessful, setIsSubscriptionSuccessful] = React.useState<boolean>(false)
  return (
    <section className='my-12 flex w-full flex-col items-center justify-center bg-[#FCF9FF]'>
      <div className='bg-white w-full max-w-maxContentWidth flex-col items-center px-5 py-9 md:mx-[20px] md:px-[60px]'>
        {isSubscriptionSuccessful ? (
          <span className='Type-XXXL-Medium my-10 flex w-[417px] self-center'>
            {i18n._('Thanks! Please check your email to confirm your subscription.')}
          </span>
        ) : (
          <div className='flex flex-col gap-x-12 gap-y-5 md:grid md:grid-cols-2 '>
            <div className='hidden h-full w-full md:block'>
              <Image
                unoptimized
                src={bgImage.src}
                className='mb-5 rounded-[8px] object-cover'
                alt='image'
                layout='fill'
              />
            </div>
            <div className='flex w-full flex-col items-center justify-center'>
              <h2 className='Type-XXL-Medium md:Type-XXXL-Medium text-black'>
                {i18n._('Sign up to the newsletter')}
              </h2>
              <p className='text-neutral-900 Type-XL-Regular mt-2 text-opacity-90'>
                {i18n._('Join us and learn tips and tricks. No Spam! Just Smiles!')}
              </p>

              <HomePageNewsletterForm
                emailPlaceholder='Subscription email*'
                buttonText={i18n._('Submit')}
                setIsSubscriptionSuccessful={setIsSubscriptionSuccessful}
                isFromBlog={isFromBlog}
              />
            </div>
          </div>
        )}
      </div>
    </section>
  )
}

// eslint-disable-next-line import/no-default-export
export default HomePageNewsletterSection
