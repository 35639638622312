import { Image } from '@graphcommerce/image'
import { ContainerProps, ImageList, ImageListItem, Paper } from '@mui/material'
import Link from 'next/link'
import { ButtonBack, ButtonNext, CarouselProvider, Slide, Slider } from 'pure-react-carousel'
import React from 'react'
import arrowLeft from '../../../assets/images/arrow-left-outline.svg'
import arrowRight from '../../../assets/images/arrow-right-outline.svg'

export type ImageProps = {
  id: string
  image: {
    url: string
    alt?: string | null | undefined
  }
  isMobile?: boolean | null | undefined
  title?: string | null | undefined
  url?: string | null | undefined
}

export type HeroBannerProps = ContainerProps & {
  banners: ImageProps[]
  pageLinks: React.ReactNode
  headerTitle?: string | null
  headerText?: string | null
}

export function HeroBanner(props: HeroBannerProps) {
  const { banners, pageLinks, headerText, headerTitle, ...containerProps } = props
  const desktopBanner = banners && banners?.filter((item) => !item.isMobile)
  // const mobileBanner = banners && banners?.filter((item) => item.isMobile)

  return (
    <div {...containerProps} className='flex w-full items-center justify-center'>
      <div className='mx-0 grid min-h-[400px] w-full max-w-maxContentWidth grid-cols-1 gap-5 md:mx-[10%] md:grid-cols-[30fr_70fr] lg:grid-cols-[45fr_55fr]'>
        <div className='flex flex-col items-start justify-center gap-3 px-10 py-5 md:px-0 md:py-0'>
          <h1 className='md:Type-H4-Bold Type-XXXL-Bold mb-1 lg:text-[40px]'>{headerTitle}</h1>
          <h2 className='lg:Type-XL-Regular Type-Large-Regular leading-[24px] md:leading-[30px] lg:text-[16px]'>
            {headerText}
          </h2>
          <div className='flex gap-8 pt-5'>{pageLinks}</div>
        </div>
        <div className='flex h-full w-full'>
          {desktopBanner && desktopBanner?.length > 0 && (
            <CarouselProvider
              naturalSlideWidth={1}
              naturalSlideHeight={2}
              totalSlides={Math.ceil((desktopBanner?.length || 3) / 3)}
              // eslint-disable-next-line no-nested-ternary
              visibleSlides={1}
              step={1}
              infinite
              isIntrinsicHeight
              isPlaying
              interval={5000}
              className='hidden h-full w-auto min-w-max md:block md:w-full'
              touchEnabled={false}
              dragEnabled={false}
            >
              <Slider className='block h-full min-h-[440px] w-full text-center align-middle'>
                <Slide index={0} className='h-full min-h-[360px] w-full px-10 py-10'>
                  <div className='grid h-full w-full grid-cols-2 gap-4'>
                    <div className='flex h-full w-full flex-col gap-4'>
                      <Link
                        className='hero-banner-img relative block h-full w-full items-center justify-center overflow-hidden rounded-[24px] bg-cover bg-center object-cover duration-1000'
                        style={{
                          backgroundImage: `url(${desktopBanner?.at(0)?.image?.url})`,
                          boxShadow: '0px 4px 40px 0px #E6CDFF',
                        }}
                        href={desktopBanner?.at(0)?.url || ''}
                        target='_self'
                      >
                        <div className='h-full w-full bg-gradient-to-b from-transparent via-transparent via-70% to-black/40' />
                        <h3 className='Type-XXL-Bold z-5 absolute bottom-5 left-8 text-pure-white'>
                          {desktopBanner[0]?.title}
                        </h3>
                      </Link>
                      <Link
                        className='hero-banner-img relative block h-full w-full items-center justify-center overflow-hidden rounded-[24px] bg-cover bg-center object-cover duration-1000'
                        style={{
                          backgroundImage: `url(${desktopBanner?.at(1)?.image?.url})`,
                          boxShadow: '0px 4px 40px 0px #E6CDFF',
                        }}
                        href={desktopBanner?.at(1)?.url || ''}
                        target='_self'
                      >
                        <div className='h-full w-full bg-gradient-to-b from-transparent via-transparent via-70% to-black/40' />
                        <h3 className='Type-XXL-Bold z-5 absolute bottom-5 left-8 text-pure-white'>
                          {desktopBanner[1]?.title}
                        </h3>
                      </Link>
                    </div>
                    <Link
                      className='hero-banner-img relative block h-full w-full items-center justify-center overflow-hidden rounded-[24px] bg-cover bg-center object-cover duration-1000'
                      style={{
                        backgroundImage: `url(${desktopBanner?.at(2)?.image?.url})`,
                        boxShadow: '0px 4px 40px 0px #E6CDFF',
                      }}
                      href={desktopBanner?.at(2)?.url || ''}
                      target='_self'
                    >
                      <div className='h-full w-full bg-gradient-to-b from-transparent via-transparent via-70% to-black/40' />
                      <h3 className='Type-XXL-Bold z-5 absolute bottom-5 left-8 text-pure-white'>
                        {desktopBanner[2]?.title}
                      </h3>
                    </Link>
                  </div>
                </Slide>
                {desktopBanner?.length > 3 && (
                  <Slide index={1} className='h-full min-h-[360px] w-full px-10 py-10'>
                    <div className='grid h-full w-full grid-cols-2 gap-4'>
                      <Link
                        className='hero-banner-img relative block h-full w-full items-center justify-center overflow-hidden rounded-[24px] bg-cover bg-center object-cover duration-1000'
                        style={{
                          backgroundImage: `url(${desktopBanner?.at(3)?.image?.url})`,
                          boxShadow: '0px 4px 40px 0px #E6CDFF',
                        }}
                        href={desktopBanner?.at(3)?.url || ''}
                        target='_self'
                      >
                        <div className='h-full w-full bg-gradient-to-b from-transparent via-transparent via-70% to-black/40' />
                        <h3 className='Type-XXL-Bold z-5 absolute bottom-5 left-8 text-pure-white'>
                          {desktopBanner[3]?.title}
                        </h3>
                      </Link>
                      <div className='flex h-full flex-col gap-4'>
                        <Link
                          className='hero-banner-img relative block h-full w-full items-center justify-center overflow-hidden rounded-[24px] bg-cover bg-center object-cover shadow-xl shadow-[#EFDFFF] duration-1000'
                          style={{
                            backgroundImage: `url(${desktopBanner?.at(4)?.image?.url})`,
                            boxShadow: '0px 4px 40px 0px #E6CDFF',
                          }}
                          href={desktopBanner?.at(4)?.url || ''}
                          target='_self'
                        >
                          <div className='h-full w-full bg-gradient-to-b from-transparent via-transparent via-70% to-black/40' />
                          <h3 className='Type-XXL-Bold z-5 absolute bottom-5 left-8 text-pure-white'>
                            {desktopBanner[4]?.title}
                          </h3>
                        </Link>
                        <Link
                          className='hero-banner-img relative block h-full w-full items-center justify-center overflow-hidden rounded-[24px] bg-cover bg-center object-cover shadow-xl shadow-[#EFDFFF] duration-1000'
                          style={{
                            backgroundImage: `url(${desktopBanner?.at(5)?.image?.url})`,
                            boxShadow: '0px 4px 40px 0px #E6CDFF',
                          }}
                          href={desktopBanner?.at(5)?.url || ''}
                          target='_self'
                        >
                          <div className='h-full w-full bg-gradient-to-b from-transparent via-transparent via-70% to-black/40' />
                          <h3 className='Type-XXL-Bold z-5 absolute bottom-5 left-8 text-pure-white'>
                            {desktopBanner[5]?.title}
                          </h3>
                        </Link>
                      </div>
                    </div>
                  </Slide>
                )}
                {desktopBanner?.length > 6 && (
                  <Slide index={2} className='h-full min-h-[360px] w-full px-10 py-10'>
                    <div className='grid h-full w-full grid-cols-2 gap-4'>
                      <div className='flex h-full w-full flex-col gap-4'>
                        <Link
                          className='hero-banner-img relative block h-full w-full items-center justify-center overflow-hidden rounded-[24px] bg-cover bg-center object-cover duration-1000'
                          style={{
                            backgroundImage: `url(${desktopBanner?.at(6)?.image?.url})`,
                            boxShadow: '0px 4px 40px 0px #E6CDFF',
                          }}
                          href={desktopBanner?.at(6)?.url || ''}
                          target='_self'
                        >
                          <div className='h-full w-full bg-gradient-to-b from-transparent via-transparent via-70% to-black/40' />
                          <h3 className='Type-XXL-Bold z-5 absolute bottom-5 left-8 text-pure-white'>
                            {desktopBanner[6]?.title}
                          </h3>
                        </Link>
                        <Link
                          className='hero-banner-img relative block h-full w-full items-center justify-center overflow-hidden rounded-[24px] bg-cover bg-center object-cover duration-1000'
                          style={{
                            backgroundImage: `url(${desktopBanner?.at(7)?.image?.url})`,
                            boxShadow: '0px 4px 40px 0px #E6CDFF',
                          }}
                          href={desktopBanner?.at(7)?.url || ''}
                          target='_self'
                        >
                          <div className='h-full w-full bg-gradient-to-b from-transparent via-transparent via-70% to-black/40' />
                          <h3 className='Type-XXL-Bold z-5 absolute bottom-5 left-8 text-pure-white'>
                            {desktopBanner[7]?.title}
                          </h3>
                        </Link>
                      </div>
                      <Link
                        className='hero-banner-img relative block h-full w-full items-center justify-center overflow-hidden rounded-[24px] bg-cover bg-center object-cover duration-1000'
                        style={{
                          backgroundImage: `url(${desktopBanner?.at(8)?.image?.url})`,
                          boxShadow: '0px 4px 40px 0px #E6CDFF',
                        }}
                        href={desktopBanner?.at(8)?.url || ''}
                        target='_self'
                      >
                        <div className='h-full w-full bg-gradient-to-b from-transparent via-transparent via-70% to-black/40' />
                        <h3 className='Type-XXL-Bold z-5 absolute bottom-5 left-8 text-pure-white'>
                          {desktopBanner[8]?.title}
                        </h3>
                      </Link>
                    </div>
                  </Slide>
                )}
                {desktopBanner?.length > 9 && (
                  <Slide index={4} className='h-full min-h-[360px] w-full px-10 py-10'>
                    <div className='grid h-full w-full grid-cols-2 gap-4'>
                      <Link
                        className='hero-banner-img relative block h-full w-full items-center justify-center overflow-hidden rounded-[24px] bg-cover bg-center object-cover shadow-xl shadow-[#EFDFFF] duration-1000'
                        style={{
                          backgroundImage: `url(${desktopBanner?.at(9)?.image?.url})`,
                          boxShadow: '0px 4px 40px 0px #E6CDFF',
                        }}
                        href={desktopBanner?.at(9)?.url || ''}
                        target='_self'
                      >
                        <div className='h-full w-full bg-gradient-to-b from-transparent via-transparent via-70% to-black/40' />
                        <h3 className='Type-XXL-Bold z-5 absolute bottom-5 left-8 text-pure-white'>
                          {desktopBanner[9]?.title}
                        </h3>
                      </Link>
                      <div className='flex h-full flex-col gap-4'>
                        <Link
                          className='hero-banner-img relative block h-full w-full items-center justify-center overflow-hidden rounded-[24px] bg-cover bg-center object-cover shadow-xl shadow-[#EFDFFF] duration-1000'
                          style={{
                            backgroundImage: `url(${desktopBanner?.at(10)?.image?.url})`,
                            boxShadow: '0px 4px 40px 0px #E6CDFF',
                          }}
                          href={desktopBanner?.at(10)?.url || ''}
                          target='_self'
                        >
                          <div className='h-full w-full bg-gradient-to-b from-transparent via-transparent via-70% to-black/40' />
                          <h3 className='Type-XXL-Bold z-5 absolute bottom-5 left-8 text-pure-white'>
                            {desktopBanner[10]?.title}
                          </h3>
                        </Link>
                        <Link
                          className='hero-banner-img relative block h-full w-full items-center justify-center overflow-hidden rounded-[24px] bg-cover bg-center object-cover shadow-xl shadow-[#EFDFFF] duration-1000'
                          style={{
                            backgroundImage: `url(${desktopBanner?.at(11)?.image?.url})`,
                            boxShadow: '0px 4px 40px 0px #E6CDFF',
                          }}
                          href={desktopBanner?.at(11)?.url || ''}
                          target='_self'
                        >
                          <div className='h-full w-full bg-gradient-to-b from-transparent via-transparent via-70% to-black/40' />
                          <h3 className='Type-XXL-Bold z-5 absolute bottom-5 left-8 text-pure-white'>
                            {desktopBanner[11]?.title}
                          </h3>
                        </Link>
                      </div>
                    </div>
                  </Slide>
                )}
              </Slider>
            </CarouselProvider>
          )}
          {desktopBanner && desktopBanner?.length > 0 && (
            <CarouselProvider
              naturalSlideWidth={1}
              naturalSlideHeight={2}
              totalSlides={Math.ceil((desktopBanner?.length || 3) / 3)}
              // eslint-disable-next-line no-nested-ternary
              visibleSlides={1}
              step={1}
              infinite
              isIntrinsicHeight
              isPlaying
              interval={5000}
              className='grid h-full w-full grid-cols-1 md:hidden'
            >
              <Slider className='h-full min-h-[400px] w-full align-middle'>
                <Slide index={0} className='min-h-[360px] w-full py-10'>
                  <div className='flex h-full w-full flex-col gap-5'>
                    <Link
                      className='hero-banner-img relative block h-full w-full items-center justify-center overflow-hidden  bg-cover bg-center object-cover duration-1000'
                      style={{
                        backgroundImage: `url(${desktopBanner?.at(0)?.image?.url})`,
                        boxShadow: '0px 4px 40px 0px #E6CDFF',
                      }}
                      href={desktopBanner?.at(0)?.url || ''}
                      target='_self'
                    >
                      <div className='h-full w-full bg-gradient-to-b from-transparent via-transparent via-70% to-black/40' />
                      <h3 className='Type-XXL-Bold z-5 absolute bottom-5 left-8 text-pure-white'>
                        {desktopBanner[0]?.title}
                      </h3>
                    </Link>
                    <div className='grid h-full grid-cols-2 gap-3'>
                      <Link
                        className='hero-banner-img relative block h-full w-full items-center justify-center overflow-hidden  bg-cover bg-center object-cover duration-1000'
                        style={{
                          backgroundImage: `url(${desktopBanner?.at(1)?.image?.url})`,
                          boxShadow: '0px 4px 40px 0px #E6CDFF',
                        }}
                        href={desktopBanner?.at(1)?.url || ''}
                        target='_self'
                      >
                        <div className='h-full w-full bg-gradient-to-b from-transparent via-transparent via-70% to-black/40' />
                        <h3 className='Type-XXL-Bold z-5 absolute bottom-5 left-8 text-pure-white'>
                          {desktopBanner[1]?.title}
                        </h3>
                      </Link>
                      <Link
                        className='hero-banner-img relative block h-full w-full items-center justify-center overflow-hidden  bg-cover bg-center object-cover duration-1000'
                        style={{
                          backgroundImage: `url(${desktopBanner?.at(2)?.image?.url})`,
                          boxShadow: '0px 4px 40px 0px #E6CDFF',
                        }}
                        href={desktopBanner?.at(2)?.url || ''}
                        target='_self'
                      >
                        <div className='h-full w-full bg-gradient-to-b from-transparent via-transparent via-70% to-black/40' />
                        <h3 className='Type-XXL-Bold z-5 absolute bottom-5 left-8 text-pure-white'>
                          {desktopBanner[2]?.title}
                        </h3>
                      </Link>
                    </div>
                  </div>
                </Slide>
                {desktopBanner?.length > 3 && (
                  <Slide index={1} className='min-h-[360px] w-full py-10'>
                    <div className='flex h-full w-full flex-col gap-5'>
                      <div className='grid h-full grid-cols-2 gap-3'>
                        <Link
                          className='hero-banner-img relative block h-full w-full items-center justify-center overflow-hidden  bg-cover bg-center object-cover duration-1000'
                          style={{
                            backgroundImage: `url(${desktopBanner?.at(3)?.image?.url})`,
                            boxShadow: '0px 4px 40px 0px #E6CDFF',
                          }}
                          href={desktopBanner?.at(3)?.url || ''}
                          target='_self'
                        >
                          <div className='h-full w-full bg-gradient-to-b from-transparent via-transparent via-70% to-black/40' />
                          <h3 className='Type-XXL-Bold z-5 absolute bottom-5 left-8 text-pure-white'>
                            {desktopBanner[3]?.title}
                          </h3>
                        </Link>
                        <Link
                          className='hero-banner-img relative block h-full w-full items-center justify-center overflow-hidden  bg-cover bg-center object-cover duration-1000'
                          style={{
                            backgroundImage: `url(${desktopBanner?.at(4)?.image?.url})`,
                            boxShadow: '0px 4px 40px 0px #E6CDFF',
                          }}
                          href={desktopBanner?.at(4)?.url || ''}
                          target='_self'
                        >
                          <div className='h-full w-full bg-gradient-to-b from-transparent via-transparent via-70% to-black/40' />
                          <h3 className='Type-XXL-Bold z-5 absolute bottom-5 left-8 text-pure-white'>
                            {desktopBanner[4]?.title}
                          </h3>
                        </Link>
                      </div>
                      <Link
                        className='hero-banner-img relative block h-full w-full items-center justify-center overflow-hidden  bg-cover bg-center object-cover duration-1000'
                        style={{
                          backgroundImage: `url(${desktopBanner?.at(5)?.image?.url})`,
                          boxShadow: '0px 4px 40px 0px #E6CDFF',
                        }}
                        href={desktopBanner?.at(5)?.url || ''}
                        target='_self'
                      >
                        <div className='h-full w-full bg-gradient-to-b from-transparent via-transparent via-75% to-black/40' />
                        <h3 className='Type-XXL-Bold z-5 absolute bottom-5 left-8 text-pure-white'>
                          {desktopBanner[5]?.title}
                        </h3>
                      </Link>
                    </div>
                  </Slide>
                )}
                {desktopBanner?.length > 6 && (
                  <Slide index={2} className='min-h-[360px] w-full py-10'>
                    <div className='flex h-full w-full flex-col gap-5'>
                      <Link
                        className='hero-banner-img relative block h-full w-full items-center justify-center overflow-hidden  bg-cover bg-center object-cover duration-1000'
                        style={{
                          backgroundImage: `url(${desktopBanner?.at(6)?.image?.url})`,
                          boxShadow: '0px 4px 40px 0px #E6CDFF',
                        }}
                        href={desktopBanner?.at(6)?.url || ''}
                        target='_self'
                      >
                        <div className='h-full w-full bg-gradient-to-b from-transparent via-transparent via-70% to-black/40' />
                        <h3 className='Type-XXL-Bold z-5 absolute bottom-5 left-8 text-pure-white'>
                          {desktopBanner[6]?.title}
                        </h3>
                      </Link>
                      <div className='grid h-full grid-cols-2 gap-3'>
                        <Link
                          className='hero-banner-img relative block h-full w-full items-center justify-center overflow-hidden  bg-cover bg-center object-cover duration-1000'
                          style={{
                            backgroundImage: `url(${desktopBanner?.at(7)?.image?.url})`,
                            boxShadow: '0px 4px 40px 0px #E6CDFF',
                          }}
                          href={desktopBanner?.at(7)?.url || ''}
                          target='_self'
                        >
                          <div className='h-full w-full bg-gradient-to-b from-transparent via-transparent via-70% to-black/40' />
                          <h3 className='Type-XXL-Bold z-5 absolute bottom-5 left-8 text-pure-white'>
                            {desktopBanner[7]?.title}
                          </h3>
                        </Link>
                        <Link
                          className='hero-banner-img relative block h-full w-full items-center justify-center overflow-hidden  bg-cover bg-center object-cover duration-1000'
                          style={{
                            backgroundImage: `url(${desktopBanner?.at(8)?.image?.url})`,
                            boxShadow: '0px 4px 40px 0px #E6CDFF',
                          }}
                          href={desktopBanner?.at(8)?.url || ''}
                          target='_self'
                        >
                          <div className='h-full w-full bg-gradient-to-b from-transparent via-transparent via-70% to-black/40' />
                          <h3 className='Type-XXL-Bold z-5 absolute bottom-5 left-8 text-pure-white'>
                            {desktopBanner[8]?.title}
                          </h3>
                        </Link>
                      </div>
                    </div>
                  </Slide>
                )}
                {desktopBanner?.length > 9 && (
                  <Slide index={3} className='min-h-[360px] w-full py-10'>
                    <div className='flex h-full w-full flex-col gap-5'>
                      <div className='grid h-full grid-cols-2 gap-3'>
                        <Link
                          className='hero-banner-img relative block h-full w-full items-center justify-center overflow-hidden  bg-cover bg-center object-cover duration-1000'
                          style={{
                            backgroundImage: `url(${desktopBanner?.at(1)?.image?.url})`,
                            boxShadow: '0px 4px 40px 0px #E6CDFF',
                          }}
                          href={desktopBanner?.at(9)?.url || ''}
                          target='_self'
                        >
                          <div className='h-full w-full bg-gradient-to-b from-transparent via-transparent via-70% to-black/40' />
                          <h3 className='Type-XXL-Bold z-5 absolute bottom-5 left-8 text-pure-white'>
                            {desktopBanner[9]?.title}
                          </h3>
                        </Link>
                        <Link
                          className='hero-banner-img relative block h-full w-full items-center justify-center overflow-hidden  bg-cover bg-center object-cover duration-1000'
                          style={{
                            backgroundImage: `url(${desktopBanner?.at(10)?.image?.url})`,
                            boxShadow: '0px 4px 40px 0px #E6CDFF',
                          }}
                          href={desktopBanner?.at(10)?.url || ''}
                          target='_self'
                        >
                          <div className='h-full w-full bg-gradient-to-b from-transparent via-transparent via-70% to-black/40' />
                          <h3 className='Type-XXL-Bold z-5 absolute bottom-5 left-8 text-pure-white'>
                            {desktopBanner[10]?.title}
                          </h3>
                        </Link>
                      </div>
                      <Link
                        className='hero-banner-img relative block h-full w-full items-center justify-center overflow-hidden  bg-cover bg-center object-cover duration-1000'
                        style={{
                          backgroundImage: `url(${desktopBanner?.at(11)?.image?.url})`,
                          boxShadow: '0px 4px 40px 0px #E6CDFF',
                        }}
                        href={desktopBanner?.at(11)?.url || ''}
                        target='_self'
                      >
                        <div className='h-full w-full bg-gradient-to-b from-transparent via-transparent via-70% to-black/40' />
                        <h3 className='Type-XXL-Bold z-5 absolute bottom-5 left-8 text-pure-white'>
                          {desktopBanner[11]?.title}
                        </h3>
                      </Link>
                    </div>
                  </Slide>
                )}
              </Slider>
            </CarouselProvider>
          )}
        </div>
      </div>
    </div>
  )
}
