import { Image } from '@graphcommerce/image'
import { Tooltip } from '@mui/material'
import icon3 from '../../assets/images/home-customer-obsessed.svg'
import icon1 from '../../assets/images/home-highest-quality-standard-icon.svg'
import icon4 from '../../assets/images/home-keeping-it-green-icon.svg'
import icon2 from '../../assets/images/home-unlimited-proofs.svg'
import { IconDescriptionGrid } from './WhyUseTempli'

export function HeroBannerIcons() {
  const data: IconDescriptionGrid[] = [
    {
      icon: icon1,
      title: 'Highest quality standards',
      details: 'For all of our products and print production.',
    },
    {
      icon: icon2,
      title: 'Unlimited proofs',
      details: 'Get unlimited free proofs with every order.',
    },
    {
      icon: icon3,
      title: 'Customer obsessed',
      details: 'Excellent support at every stage of your order.',
    },
    {
      icon: icon4,
      title: 'Keeping it green',
      details: 'Compostable and recyclable items that are easy on the planet.',
    },
  ]
  return (
    <div className='mb-4 hidden w-full flex-col items-center justify-center md:flex'>
      <div className='bg-trans mx-[10px] w-full max-w-maxContentWidth px-5 md:mx-[20px] md:px-[60px]'>
        <div className=' grid grid-cols-4 items-center justify-center justify-items-center gap-3'>
          {data.map((item) => (
            <Tooltip title={item.details} arrow>
              <div className='flex flex-col items-center justify-center gap-y-2 text-black'>
                <Image
                  unoptimized
                  src={item?.icon}
                  height={60}
                  width={60}
                  layout='fixed'
                  className='flex h-[60px] w-[60px] items-center justify-center'
                />
                <span className='Type-XL-Medium hidden text-center md:block'>{item.title}</span>
                <span className='Type-Large-Regular hidden text-center md:block'>
                  {item.details}
                </span>
              </div>
            </Tooltip>
          ))}{' '}
        </div>
      </div>
    </div>
  )
}
